import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class StageFourService {
  static createStageFour (data) {
    return axios.post(`${url}/app/stage-four/create`, data)
  }
}

export default StageFourService
