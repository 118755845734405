import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class DraftService {
  static saveDraft (data) {
    return axios.post(`${url}/app/progress/`, data)
  }

  static getDraft (id, stage) {
    return axios.get(`${url}/app/progress/${id}/${stage}`)
  }
}

export default DraftService
